import {
  Content,
  CreateFangout,
  InlineInput,
  InlineInputsContainer,
  validation,
} from "../createFangout/createFangout";
import { withTheme } from "styled-components";
import moment from "moment";
import {
  extractError,
  getLocationObject,
  handleLoadAddressOptions,
} from "../../utils";
import { saveFangoutRequestData } from "./requestFangoutActions";
import { connect } from "react-redux";
import createFangoutService from "../createFangout/createFangoutService";
import { setFangoutData, setFangouts } from "../fangouts/fangoutsActions";
import { getRequests } from "../requests/requestsActions";
import { colourStyles, locationInputColourStyles } from "../Login/utils";
import { Container, Label } from "../../styling/globalStyling";
import GoBack from "../../components/goBack/goBack";
import Heading from "../../components/heading/heading";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Input, { Description } from "../../components/input/input";
import {
  billValues,
  dateFormat,
  getTypeDescriptions,
  getTypeValues,
} from "../../constants";
import Select from "react-select";
import React from "react";
import Spinner from "../../components/Spinner/Spinner";
import NeedVerification from "../../components/NeedVerification/NeedVerification";
import { TextArea } from "../../components/fangoutComponent/requestFangoutComponent";
import AvailabilityService from "../availability/availabilityService";

class RequestFangout extends CreateFangout {
  componentDidMount() {
    super.componentDidMount();
    AvailabilityService.getAvailabilitiesById(
      this.props.match.params.userId
    ).then((res) => {
      this.setState({
        available: res.data.isAvailable,
        availabilities: res.data.availability,
      });
    });
  }

  goToRequests = (selectedTab = undefined, fId) => {
    this.props.setFangouts();
    this.props.getRequests();
    let route = `/requests/${selectedTab}`;
    if (fId) {
      route = route + `/${fId}`;
    }
    this.props.history.push(route);
  };

  createFangout = () => {
    const {
      bill,
      audience,
      type,
      addressObject,
      date,
      time,
      duration,
      description,
    } = this.state;
    const timeArray = time.split(":");
    const mdate = moment(date).hours(timeArray[0]).minutes(timeArray[1]);
    const utcTime = mdate.utc().format();
    const celebrityId = this.props.match.params.userId;
    const data = {
      billType: type.value === "CASUAL" ? bill.value : null,
      location: getLocationObject(addressObject.value),
      currency: "EUR",
      date: utcTime,
      fangoutType: type.value,
      numOfAttendees: parseInt(audience),
      celebrityId: celebrityId,
      duration: parseInt(duration),
      description: description,
    };

    this.setState({ error: "", disabled: true, loading: true });
    createFangoutService
      .requestFangout(celebrityId, data)
      .then((res) => {
        this.setState({ showPopup: true, loading: false });
        this.props.setFangoutData(null);
        this.props.saveFangoutRequestData(null);
        this.goToRequests(1, res.data.id);
      })
      .catch((e) => {
        this.setState({ error: extractError(e) });
      })
      .finally(() => this.setState({ disabled: false, loading: false }));

    // Save data in redux
    // this.props.saveFangoutRequestData(data)
    // this.props.history.push('/payment')
  };

  validateLocation = () => {
    let isInvalid = true;
    if (this.state.available && this.state.availabilities === null) {
      isInvalid = false;
    } else if (this.state.available && this.state.availabilities.length) {
      this.state.availabilities.forEach((av) => {
        if (av?.location?.hereId === this.state.addressObject?.value?.id) {
          isInvalid = false;
        }
      });
    }
    this.setState({ locationHasError: isInvalid });
  };

  validateDate = () => {
    if (this.state.available && this.state.availabilities === null) {
      return "success";
    } else if (this.state.available && this.state.availabilities.length) {
      let isValid = "error";
      const currentDate = moment(this.state.date);
      this.state.availabilities.forEach((av) => {
        if (currentDate.isBetween(av.start, av.end)) {
          isValid = "success";
        }
      });
      return isValid;
    } else {
      return "error";
    }
  };

  setLocationAndValidate = (location) => {
    this.setState({ addressObject: location }, () => {
      this.validateLocation();
    });
  };

  render() {
    const { loading } = this.state;
    const lang = this.props.lang;
    const locationSelectStyles = locationInputColourStyles(
      this.props.theme,
      this.state.locationHasError
    );
    const selectStyles = colourStyles(this.props.theme);
    const isRequest =
      this.props.location.pathname.indexOf("requestfangout") !== -1;
    return this.props.userData.verified === "VERIFIED" ? (
      <Container>
        {loading && <Spinner fullscreen />}
        <GoBack customAction={() => this.props.history.push("/home")} />
        <Heading
          title={
            isRequest ? lang.requestFangout_title : lang.createFangout_title
          }
        />
        <Content className={"p016 noscroll"}>
          <Label>{lang.label_location}</Label>
          <AsyncSelect
            id={"address"}
            value={this.state.addressObject}
            onChange={(location) => this.setLocationAndValidate(location)}
            loadOptions={handleLoadAddressOptions}
            defaultOptions={this.state.addressOptions}
            className={"address"}
            styles={locationSelectStyles}
            cacheOptions
            isvalid={"error"}
          />
          <InlineInputsContainer className={""}>
            <InlineInput>
              <Input
                label={lang.label_date}
                type={"date"}
                value={this.state.date}
                inputstyle={{
                  minWidth: "-webkit-fill-available",
                  paddingRight: "5px",
                  paddingLeft: "8px",
                }}
                onChange={(e) => this.setState({ date: e.target.value })}
                min={moment().format(dateFormat)}
                isvalid={this.validateDate()}
              />
            </InlineInput>
            <InlineInput>
              <Input
                label={lang.label_time}
                type={"time"}
                value={this.state.time}
                inputstyle={{
                  minWidth: "-webkit-fill-available",
                  paddingRight: "5px",
                  paddingLeft: "8px",
                }}
                onChange={(e) => this.setState({ time: e.target.value })}
              />
            </InlineInput>
          </InlineInputsContainer>
          <Input
            value={this.state.duration}
            label={lang.label_duration}
            type={"number"}
            icon={"fas fa-watch"}
            descriptionPositionUp
            description={"(h)"}
            min={1}
            onChange={(e) => this.setState({ duration: e.target.value })}
            isvalid={this.state.duration < 1 ? "error" : "success"}
          />
          <div className={"relative"}>
            <Label>{lang.label_type}</Label>
            <Select
              styles={selectStyles}
              value={this.state.type}
              options={getTypeValues(this.props.lang)}
              defaultValue={getTypeValues(this.props.lang)[0]}
              onChange={(e) => this.setState({ type: e })}
            />
            <Description>
              {getTypeDescriptions(this.props.lang)[this.state.type.value]}
            </Description>
          </div>
          {this.state.type.value === "CASUAL" && (
            <div>
              <Label>{lang.label_bill}</Label>
              <Select
                styles={selectStyles}
                value={this.state.bill}
                defaultValue={billValues(this.props.lang)[0]}
                options={billValues(this.props.lang)}
                onChange={(e) => this.setState({ bill: e })}
              />
            </div>
          )}
          <Input
            value={this.state.audience}
            label={lang.label_audience}
            type={"number"}
            description={"(UP TO)"}
            descriptionPositionUp
            min={1}
            max={100}
            onChange={(e) => this.setState({ audience: e.target.value })}
          />
          <Label>{lang.description}</Label>
          <TextArea
            value={this.state.description}
            onChange={(e) => this.setState({ description: e.target.value })}
          />
          <div
            className={"text-danger mt2 text-center"}
            style={{ height: "22px" }}
          >
            {this.state.error}
          </div>
          {(this.state.locationHasError || this.validateDate() === "error") && (
            <div
              className={"text-danger mt2 text-center"}
              style={{ height: "30px" }}
            >
              {lang.requestFangout_user_is_not_available}
            </div>
          )}
          <button
            disabled={
              validation.call(this) ||
              this.state.disabled ||
              this.state.locationHasError ||
              this.validateDate() === "error"
            }
            style={{ marginTop: "12px", marginBottom: "32px" }}
            onClick={this.createFangout}
          >
            {this.props.match && this.props.match.params.userId
              ? lang.label_request
              : lang.label_create}{" "}
            Fangout
          </button>
        </Content>
      </Container>
    ) : (
      <NeedVerification
        openModalDefault={true}
        cancelAction={() => this.props.history.push("/")}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.fangouts.fangoutRequestData,
    lang: state.home.language,
    userData: state.profile.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveFangoutRequestData: (data) => saveFangoutRequestData(dispatch, data),
    setFangoutData: (data) => setFangoutData(dispatch, data),
    setFangouts: () => setFangouts(dispatch),
    getRequests: () => getRequests(dispatch),
  };
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(RequestFangout)
);
