import React, {useContext, useState} from 'react'
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {useHistory} from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import GoBack from "../../components/goBack/goBack";
import Heading from "../../components/heading/heading";
import {currencyMap} from "../../constants";
import {CARD_OPTIONS, FangoutPaymentDetails, FlexCenter, FormRow} from "../../components/stripe/paymentForm";
import {ThemeContext} from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import stripeService from "../../components/stripe/stripeService";
import {getUserData} from "../profile/profileActions";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY)

const PremiumPayment = (props) => {
	const {premium} = props
	const dispatch = useDispatch()
	const history = useHistory()
	const stripe = useStripe()
	const elements = useElements()

	const lang = useSelector(state => state.home.language)
	const [loading, setLoading] = useState(false)
	const themeContext = useContext(ThemeContext);
	const options = CARD_OPTIONS(themeContext.text)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState('')
	const [cElement, setCardElement] = useState(null)

	const handleSubmit = async (e) => {
		e.stopPropagation()
		e.preventDefault()

		setLoading(true)
		const {error, paymentMethod} = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement)
		})

		if (!error) {
			try {
				const {id} = paymentMethod
				const response = await stripeService.makePremiumPayment({
					paymentMethodId: id,
					durationMonths: parseInt(premium.months)			//this should be done at backend for safety
				})

				// TODO check error handling
				if (response) {
					setLoading(false)
					setSuccess(true)
					getUserData(dispatch)
					setTimeout(() => {
						history.push('/profile')
					}, 500)
				} else {
					setError('Something gone wrong')
				}
			} catch (e) {
				setError(e.message)
				setLoading(false)
			}
		} else {
			setLoading(false)
			console.log('error', error)
		}
	}


	return (
		<div className={'p016'}>
			{loading && <Spinner fullscreen/>}
			<GoBack/>
			<Heading title={lang.premium_payment}/>
			{!success ?
				<div className={'stripeContainer'}>
					{premium && <FangoutPaymentDetails>
						<div>{lang.premium_label_months} {premium.months} {lang.premium_months}</div>
						<div>{premium.months < 12 ? premium.months * 50 : premium.months * 40} {currencyMap[premium.currency]}</div>
					</FangoutPaymentDetails>}
					<div className={'FormGroup'}>
						<FormRow>
							<CardElement options={options} onChange={setCardElement}/>
						</FormRow>
					</div>
					<button
						disabled={!premium || !stripe || !cElement || !cElement?.complete || loading}
						onClick={handleSubmit}
						className={'mt2'}>
						{lang.pay} {premium.months < 12 ? premium.months * 50 : premium.months * 40}{currencyMap[premium?.currency]}
					</button>
					{error && <div className="text-danger">{error}</div>}
				</div> : <FlexCenter>
					<h4>Paid successfully</h4>
				</FlexCenter>
			}
		</div>)
}

const StripePremiumPayment = () => {
	const history = useHistory()
	const {premium} = history.location?.state || {}
	if (!premium) {
		history.push('/profile')
		return null
	}
	return (<Elements stripe={stripePromise}>
		<PremiumPayment premium={premium} />
	</Elements>)
}

export default StripePremiumPayment