import React from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import PaymentForm from "./paymentForm";
import {useHistory} from "react-router-dom";
import styled from 'styled-components'

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY)

export default function StripeContainer(props) {
  const history = useHistory()
  const {fangout} = history.location

  return (<WhitePaymentContainer>
    <Elements stripe={stripePromise}>
      <PaymentForm fangout={fangout} cardOptions={CARD_OPTIONS}/>
    </Elements>
  </WhitePaymentContainer>)
}

export const WhitePaymentContainer = styled.div`
  color: #000;
  background: #fff;
  height: 100vh;
`


export const CARD_OPTIONS = {
	hidePostalCode: true,
	style: {
		iconStyle: 'solid',
		base: {
			iconColor: "#000",
			color: '#000',
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": {color: "#fce883"},
			"::placeholder": {color: "#87bbfd"}
		},
		invalid: {
			iconColor: "#b00",
			color: "#b00"
		}
	}
}