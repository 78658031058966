import React from 'react';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import { dateTimeFormat } from '../myShows/myShows';
import { useHistory } from 'react-router-dom';
import { ShowOwner } from '../show/Show';

const ShowItem = (props: any) => {
  const { show: s, onClick } = props;
  const history = useHistory();
  const theme = useTheme();

  return (
    <ShowItemWrapper
      onClick={() => (onClick ? onClick() : history.push(`/show/${s.id}`))}
    >
      <div className={'flex gap15'}>
        <div className='details'>
          <div className={'head'}>
            <div className={'title'}>
              {s.showTitle}{' '}
              {s.isDraft && (
                <span style={{ color: theme.yellow }}>(Draft)</span>
              )}
            </div>
          </div>
          <ShowOwner style={{ background: 'transparent' }} className={'m0 p0'}>
            <img
              className={'userImage'}
              src={s.celebrityDto?.profilePhoto}
              alt={'show-owner'}
            />
            <div className={'owner'}>@{s.celebrityDto?.username}</div>
          </ShowOwner>
          <div>{moment(s.showDate).format(dateTimeFormat)}</div>
          <div className={'location link'}>
            <i className={'fas fa-location-dot'} />
            {s?.location?.title}
          </div>
        </div>
        <img src={s.eventImage} />
      </div>
    </ShowItemWrapper>
  );
};

export default ShowItem;

export const ShowItemWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.secondBackground};
  margin-bottom: 16px;
  display: block;
  border-radius: 10px;
  padding: 6px 8px;
  cursor: pointer;

  .details {
    flex-grow: 1;

    .head {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 10px 0 0;

      .title {
        font-weight: 600;

        .primary {
          font-weight: 400;
          color: ${({ theme }) => theme.primary};
        }

        .yellow {
          font-weight: 400;
          color: ${({ theme }) => theme.yellow};
        }
      }
    }
  }

  img {
    cursor: pointer;
    border-radius: 8px;
    width: 60px;
    height: 100px;
  }

  .location {
    font-size: 12px;
    width: fit-content;

    i {
      margin-right: 5px;
    }
  }
`;
