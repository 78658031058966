import React from 'react';
import { debounce } from './pages/Login/utils';
import heremapsService from './services/heremaps/heremapsService';
import { socNetMap } from './pages/personalDetails/personalDetails';
import * as constants from './constants';
import notificationsService from './notificationsService';
import { messaging } from './init-fcm';
import moment from 'moment';
import { foutStatus } from './constants';
import toast from 'react-hot-toast';

export const handleLoadAddressOptions = debounce(
  async (inputValue, callback) => {
    if (inputValue.length > 3) {
      const optionsPromise = await heremapsService.geocode(inputValue);
      // const locations = optionsPromise.data.suggestions.map(async s => await hereMapService.getLocationByLocationId(s.locationId))
      const suggestions = optionsPromise.data.items
        .filter((s) => s.id)
        .map((s) => ({
          label: s.address.label,
          value: s,
        }));
      callback(suggestions);
    }
  },
  500
);

export const getLocationObject = (l) => {
  if (!l) return {};
  return {
    title: l?.address?.label || l.title,
    lat: l.lat || l?.position.lat,
    lon: l.lon || l?.position.lng,
    hereId: l.hereId || l.id,
    countryCode: l?.countryCode || l?.address?.countryCode,
    countryName: l?.countryName || l?.address?.countryName,
    city: l.city || l?.address?.city,
    state: l.state || l?.address?.state,
    street: l.street || l?.address?.street,
    houseNumber: l.street || l?.address?.street,
  };
};

export const sortAvByDate = (a, b) => sortByDate(a.start, b.start);

export const sortByFangoutStatus = (a, b) => {
  if (a.status < b.status) {
    return 1;
  } else if (a.status > b.status) {
    return -1;
  } else {
    return 0;
  }
};

export const sortByDate = (a, b, desc = false) => {
  if (moment(a).isAfter(moment(b))) {
    return desc ? -1 : 1;
  } else if (moment(a).isBefore(moment(b))) {
    return desc ? 1 : -1;
  } else {
    return 0;
  }
};

export const sortPendingFirst = (a, b) => {
  if (a.status === foutStatus.PENDING && b.status === foutStatus.CONFIRMED) {
    return -1;
  } else if (
    a.status === foutStatus.CONFIRMED &&
    b.status === foutStatus.PENDING
  ) {
    return 1;
  } else {
    return 0;
  }
};

export function getSocNetworks(socNets) {
  let socNetObject = socNetMap;

  if (!socNets) {
    return socNetObject;
  }

  socNets.forEach((sn) => {
    socNetObject[sn.type].url = sn.url;
  });
  return socNetObject;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shareFeature(url) {
  if (navigator.share) {
    navigator
      .share({
        // title: 'Referal link',
        // text: 'Referal URL',
        url,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  } else {
    navigator.clipboard.writeText(url);
    toast.success('Copied to clipboard');
  }
}

export function redirectIfLoggedIn(history) {
  const session = localStorage.getItem(constants.SESSION_KEY);
  let accessToken = null;
  if (session) {
    try {
      accessToken = JSON.parse(session).accessToken;
    } catch (e) {
      console.log('error', e);
    }
  }
  if (accessToken) {
    history && history.push('/');
  }
}

export function getDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'desktop';
}

export function subscribeToFirebase() {
  if (messaging) {
    try {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(async (reg) => {
          try {
            const token = await messaging.getToken({
              vapidKey: process.env.REACT_APP_VAPIDKEY,
              serviceWorkerRegistration: reg,
            });

            const deviceType = getDeviceType().toUpperCase();
            const notif = { deviceType, token };
            notificationsService.subscribeUser(notif).then(() => {
              localStorage.setItem('notification', JSON.stringify(notif));
            });
          } catch (e) {
            console.log('%c [FIREBASE] ', 'color: red', e);
          }
        });
      }
    } catch (e) {
      console.log('Unable to get permission to notify.', e);
    }

    // messaging.onMessage(msg => {
    // 	console.log('received on front', msg)
    // 	if ('serviceWorker' in navigator) {
    // 		navigator.serviceWorker.getRegistration()
    // 			.then(reg => {
    // 				if (msg.data) {
    // 					reg.showNotification('FRONT ' + msg?.data?.dataTitle, msg?.data)
    // 				} else if (msg.notification) {
    // 					reg.showNotification('FRONT ' + msg?.notification?.title, msg?.notification)
    // 				}
    // 			})
    // 	}
    // })
  }
}

export const extractError = (e) => {
  return e?.response?.data?.generalError?.message || e.message;
};

export const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

export function expiredFangouts(f) {
  const mdate = moment(f.date);
  return moment().isBefore(mdate);
}

export function filterShows(s) {
  const showEndDate = moment(s.endDate);
  return moment().isBefore(showEndDate);
}

export function openMaps(e, show) {
  e.stopPropagation();
  if (show.location) {
    const { lat, lon } = show.location;
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') !== -1 ||
      navigator.platform.indexOf('iPad') !== -1 ||
      navigator.platform.indexOf('iPod') !== -1
    )
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    /* else use Google */ else
      window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
  }
}

export function urlify(text) {
  const urlRegex =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return text?.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

export function sanitizeCommaSeparatedValue(value) {
  return value.replaceAll(',', '').replaceAll('.', '');
}

export const filterDraftTicketTypes = (ticket) => {
  return !ticket.isDraft;
};

export const validateLength = (condition, value) => {
  if (!value) return undefined;
  return condition ? 'success' : 'error';
};

export const sortById = (a, b) => {
  return a.id - b.id;
};
