import React from 'react';
import Tabs from '../../components/tabs/tabs';
import { Container } from '../../styling/globalStyling';
import Heading from '../../components/heading/heading';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getMyTickets } from '../show/ticketService';
import Ticket from '../../components/ticket/Ticket';
import { ITicketType } from '../createShow/createShow';
import moment from 'moment';
import Expandable from '../../components/expandable/Expandable';
import styled from 'styled-components';

export interface ITicketProps {
  id?: number;
  invalidationDate: string;
  invalidationId: string;
  isValid: boolean;
  purchaseDate: string;
  ticketType: ITicketType;
  invalidationsLeft: number;

  [key: string]: any;
}

const isTicketValidFilter = (t: ITicketProps) => {
  const showDate = moment(t.show.endDate).add(1, 'd');
  return moment().isBefore(showDate) && t.invalidationsLeft > 0;
};
const isTicketinvalidFilter = (t: ITicketProps) => {
  const showDate = moment(t.show.endDate).add(1, 'd');
  return moment().isAfter(showDate) || t.invalidationsLeft === 0;
};

const MyTickets = () => {
  const lang = useSelector((state: any) => state.home.language);
  const { data } = useQuery(['mytickets'], getMyTickets);

  return (
    <Container>
      <Heading title={lang.tickets} />
      <Container className={'p016 pb200'}>
        {data?.data.length ? (
          data?.data
            .filter(isTicketValidFilter)
            .map((t: ITicketProps) => <Ticket key={t.id} ticket={t} />)
        ) : (
          <NothingHere>{lang.no_tickets}</NothingHere>
        )}

        {data?.data.length ? (
          <Expandable title={'History'}>
            {data?.data.filter(isTicketinvalidFilter).map((t: ITicketProps) => (
              <Ticket key={t.id} ticket={t} />
            ))}
          </Expandable>
        ) : null}
      </Container>
      <Tabs active={3} />
    </Container>
  );
};

export default MyTickets;

export const NothingHere = styled.div`
  color: ${({ theme }) => theme.gray};
  text-align: center;
  margin-top: 2rem;
`;
