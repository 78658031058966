import React from 'react';
import { Container } from '../../styling/globalStyling';
import GoBack from '../../components/goBack/goBack';
import Heading from '../../components/heading/heading';
import { withRouter } from 'react-router-dom';
import emptyAvatar from '../../assets/images/empty-avatar.jpg';
import styled, { withTheme } from 'styled-components';
import moment from 'moment';
import { billMap, currencyMap, typeMap } from '../../constants';
import fangoutsPublicService from '../fangouts/fangoutsPublicService';
import { setFangoutData } from '../fangouts/fangoutsActions';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import config from '../../config';
import { shareFeature } from '../../utils';
import { Share } from '../publicProfile/publicProfile';
import ReactGA from 'react-ga4';

class FangoutDetails extends React.Component {
  state = {
    fangout: null,
    error: '',
  };

  componentDidMount() {
    ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname });

    if (this.props.location.state && this.props.location.state.fangout) {
      this.setState({ fangout: this.props.location.state.fangout });
    } else {
      fangoutsPublicService
        .getFangoutById(this.props.match.params.id)
        .then((res) => {
          this.setState({ fangout: res.data });
        })
        .catch((e) => {
          this.setState({ error: e.message });
        });
    }
  }

  bookFangout = () => {
    this.props.setFangoutData(this.state.fangout);
    // this.props.history.push('/pay')
    this.props.history.push({ pathname: '/pay', fangout: this.state.fangout });
  };

  goToProfile = (e) => {
    e.stopPropagation();
    this.props.history.push('/public/' + this.state.fangout.celebrity.id);
  };

  editFangout = (f) => {
    this.props.history.push({
      pathname: '/editfangout/' + f.id,
      state: f,
    });
  };

  render() {
    const fangout = this.state.fangout;
    const avatarSrc =
      fangout && fangout.celebrity && fangout.celebrity.profilePhoto
        ? fangout.celebrity.profilePhoto
        : emptyAvatar;
    const isMyFangout = fangout?.celebrity?.id === this.props.userData.id;

    const lang = this.props.lang;
    return (
      <ExtContainer>
        <GoBack customAction={() => this.props.history.push('/')} />
        <Heading title={lang.details_title} />
        {isMyFangout && (
          <i
            className={'editFangout fas fa-pencil-alt'}
            onClick={() => this.editFangout(this.state.fangout)}
          />
        )}
        {fangout && (
          <DetailsContainer className={'p016'}>
            {fangout.sponsor && <FoutName>{fangout.sponsor}</FoutName>}
            <div className={'relative'} style={{ marginTop: '12px' }}>
              <img
                className={'avatar'}
                src={avatarSrc}
                alt={'avatar'}
                onClick={this.goToProfile}
              />
            </div>
            <div className={'name'} onClick={this.goToProfile}>
              {fangout.celebrity.name}
            </div>
            <div className={'username'} onClick={this.goToProfile}>
              @{fangout.celebrity.username}
            </div>
            <Share
              style={{
                alignSelf: 'flex-end',
                marginTop: '-24px',
                fontSize: '22px',
              }}
            >
              <i
                className={'icon-share'}
                onClick={() =>
                  shareFeature(
                    `${config.baseApi + config.shareApi}/fangouts/${fangout.id}`
                  )
                }
              />
            </Share>
            <div className={'controlsWrapper'}>
              <FangoutDetail
                content={fangout.location.city}
                icon={'icon-location'}
              />
              <FangoutDetail
                content={`${moment(fangout.date).format(
                  'DD MMMM YYYY'
                )} / ${moment(fangout.date).format('h:mmA')}`}
                icon={'icon-events'}
              />
              <FangoutDetail
                content={`${fangout.duration} hr(s)`}
                icon={'fas fa-hourglass-half'}
                iconStyle={{ marginLeft: '18px', width: '18px' }}
              />
              <FangoutDetail
                content={typeMap(lang)[fangout.fangoutType]}
                icon={'icon-drink'}
              />
              {fangout.fangoutType === 'CASUAL' && (
                <FangoutDetail
                  content={billMap(lang)[fangout.billType] + ' ' + lang.bill}
                  icon={'icon-bill'}
                />
              )}
              <FangoutDetail
                content={`${lang.label_audience} ${fangout.numOfAttendees}`}
                icon={'icon-request'}
              />
              <FangoutDetail
                content={fangout.price + currencyMap[fangout.currency]}
                icon={'icon-price'}
              />
              {fangout.description && (
                <FangoutDescription>{fangout.description}</FangoutDescription>
              )}
            </div>
          </DetailsContainer>
        )}
        {fangout && (
          <div
            className={'p016'}
            style={{ marginTop: '12px', marginBottom: '32px' }}
          >
            <button disabled={isMyFangout} onClick={this.bookFangout}>
              Fangout
            </button>
          </div>
        )}
      </ExtContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.home.language,
    userData: state.profile.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFangoutData: (datat) => setFangoutData(dispatch, datat),
  };
}

export default withTheme(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(FangoutDetails))
);

const FangoutDetail = (props) => {
  return (
    <FangoutDetailEl onClick={props.onClick} style={props.style}>
      <i className={`firstIcon ${props.icon}`} style={props.iconStyle} />
      <div className={'content'}>{props.content}</div>
    </FangoutDetailEl>
  );
};

const ExtContainer = styled(Container)`
  .editFangout {
    color: ${({ theme }) => theme.primary};
    position: absolute;
    top: 18px;
    right: 16px;
    font-size: 18px;
  }
`;

const FoutName = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const FangoutDetailEl = styled.div`
  margin-top: 6px;
  background-color: ${({ theme }) => theme.secondBackground};
  height: 34px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 170px;
  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.contrastText};
    &:active {
      opacity: 0.8;
    }
  }
  .firstIcon {
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-size: 16px;
  }
  .content {
    margin-left: 16px;
  }
  .arrow {
    display: flex;
    align-items: center;
    margin: 0 16px 0 auto;
  }
`;

const DetailsContainer = styled(Container)`
  align-items: center;
  .avatar {
    width: 74px;
    border-radius: 10px;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
    margin-top: 4px;
  }
  .username,
  .fullName {
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
  }
  .referal {
    margin-top: 12px;
    font-size: 12px;
    font-weight: 600;
    // margin-bottom: -24px;
    i {
      display: inline;
      &:active {
        opacity: 0.8;
      }
    }
  }
  .url {
    font-size: 10px;
    word-break: break-all;
    color: ${({ theme }) => theme.primary};
    &:active {
      opacity: 0.8;
    }
  }
  .controlsWrapper {
    margin-top: 12px;
    width: 100%;
  }
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FangoutDescription = styled.div`
  margin-top: 6px;
  padding: 6px 16px;
  background-color: ${({ theme }) => theme.secondBackground};
`;
