import React from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'

const ArrowWrapper = styled.div`
	position: absolute;
	top: 18px;
	left: 16px;
	font-size: 20px;
	i {
		cursor: pointer;
	}
`
interface IGoBackProps {
	customAction?: () => void
		defaultRoute?: string
}

const GoBack = (props: IGoBackProps) => {
	const history = useHistory()
	const {customAction, defaultRoute} = props

	const goBackHandler = () => {
		if (history.length === 2 && defaultRoute) {
			history.push(defaultRoute)
		} else if (history.length === 2) {
			history.push('/home')
		} else {
			history.goBack()
		}
	}
	return <ArrowWrapper>
		<i className={'icon-back'} onClick={customAction || goBackHandler}/>
	</ArrowWrapper>
}


export default GoBack
