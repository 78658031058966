import React from 'react'
import emptyAvatar from "../../assets/images/empty-avatar.jpg";
import {useHistory} from "react-router-dom";
import styled from 'styled-components'


const PopularProfile = props => {
	const history = useHistory()

	const goToProfile = (e, user) => {
		e.stopPropagation()
		history.push('/public/' + user.id)
	}
	const {user} = props

	return <Container>
		<div className={'relative'}>
			{user !== null ? <img
				src={user.profilePhoto || emptyAvatar}
				alt={user.name}
				onClick={(e) => goToProfile(e, user)}
				className={'userPhoto'}
			/> : <SkeletonProfileImage/>}
		</div>
		<div className={'center'}>
			{user !== null ? <div className={'name'}>{user?.name}</div> : <SkeletonUsername />}
		</div>
	</Container>
}

export default PopularProfile


const Container = styled.div`
	cursor: pointer;
	border-radius: 4px;
	display: inline-block;
	overflow: visible;
	margin-bottom: 16px;
	vertical-align: top;
}
	.relative {
		position: relative;
		text-align: center;
	}
	i {
		right: calc(50% - 45px);
	}
	img {
    border-radius: 8px;
    width: 120px;
    height: 120px;
	}
	.center {
		text-align: center;
		font-size: 14px;
		word-break: break-word;
	}
	
`


export const SkeletonProfileImage = styled.div`
	width: 120px;
	height: 120px;
	border-radius: 8px;
	margin: 0 auto;
	animation: skeleton-loading 0.5s linear infinite alternate;
	
	@keyframes skeleton-loading {
		0% {
			background-color: hsl(200, 20%, 70%);
		}
		100% {
			background-color: hsl(200, 20%, 95%);
		}
	}
`

export const SkeletonUsername = styled.div`
	width: 80px;
	height: 14px;
	border-radius: 8px;
	margin: 0 auto;
	margin-top:6px;

	animation: skeleton-loading 0.5s linear infinite alternate;
	
	@keyframes skeleton-loading {
		0% {
			background-color: hsl(200, 20%, 70%);
		}
		100% {
			background-color: hsl(200, 20%, 95%);
		}
	}
`
