import React from 'react';
import { useSelector } from 'react-redux';
import { IShow, ITicketType } from '../../createShow/createShow';
import InputNew from '../../../components/inputNew/InputNew';
import { currencyMap } from '../../../constants';

import style from './TicketRow.module.scss';

interface ITicketRowProps {
  ticket: ITicketType;
  qty: any;
  setQty: (newValue: any) => void;
  show?: IShow;
}

const TicketRow = (props: ITicketRowProps) => {
  const lang = useSelector((state: any) => state.home.language);
  const { qty, setQty, show, ticket } = props;
  const { id = '', title, description, ticketPrice } = props.ticket;
  const value = qty[id] || '';

  const handleChange = (e: any) => {
    const v = e.target.value;
    setQty({ ...qty, [id]: v });
  };

  return (
    <div className={style.ticketDetails}>
      <div className={'flex space-between align-center'}>
        <div className={style.ticketTitle}>{title}</div>
        {ticket.numberOfTickets > 0 ? (
          <div className={style.priceNqty}>
            <div className={style.ticketPrice}>
              {ticketPrice} {currencyMap[show?.currency]}
            </div>

            <InputNew
              className={style.input}
              label={lang.qty}
              type={'number'}
              value={value}
              min={0}
              onChange={handleChange}
            />
          </div>
        ) : (
          <div className='text-danger'>{lang.show_ticket_soldout}</div>
        )}
      </div>
      <div>{description}</div>
    </div>
  );
};

export default TicketRow;
