import React, { useMemo, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { WhitePaymentContainer } from '../../components/stripe/stripeContainer';
import Spinner from '../../components/Spinner/Spinner';
import GoBack from '../../components/goBack/goBack';
import Heading from '../../components/heading/heading';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import {
  CARD_OPTIONS,
  FangoutPaymentDetails,
  FlexCenter,
  FormRow,
} from '../../components/stripe/paymentForm';
import { currencyMap } from '../../constants';
import StripeLogo from '../../assets/images/logo-stripe.png';
import { Container, Flex } from '../../styling/globalStyling';
import stripeService from '../../components/stripe/stripeService';

import style from './TicketPayment.module.scss';

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const currencyFeeAddition = {
  EUR: 0.3,
  USD: 0.3,
  RSD: 30,
};

const TicketPayment = (props) => {
  const { data: paymentData } = props;
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const lang = useSelector((state) => state.home.language);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const options = CARD_OPTIONS(theme.darkGreen);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [cElement, setCardElement] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  });

  const handleSubmit = async (e) => {
    ReactGA.event({
      action: 'Click',
      category: 'Button',
      label: 'buy_tickets',
      value: paymentData.showTitle,
    });
    e.stopPropagation();
    e.preventDefault();

    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!error) {
      try {
        const { id } = paymentMethod;
        const response = await stripeService.makeTicketPayment({
          showId: paymentData.showId,
          paymentMethodId: id,
          ticketList: paymentData.tickets.map((t) => ({
            numberOfTickets: t.numberOfTickets,
            ticketTypeId: t.id,
          })), //this should be done at backend for safety
        });

        // TODO check error handling
        if (response) {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            history.push('/mytickets');
          }, 500);
        } else {
          setError('Something gone wrong');
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    } else {
      setLoading(false);
      console.log('error', error);
    }
  };

  const totals = useMemo(() => {
    let ticketsTotal = paymentData?.tickets.reduce(
      (acc, item) => item.ticketPrice * item.numberOfTickets + acc,
      0
    );

    const bankFee =
      currencyFeeAddition[paymentData.currency] +
      Number((ticketsTotal * 0.05).toFixed(2));

    const total = ticketsTotal + Number(bankFee);

    return {
      ticketsTotal,
      bankFee,
      total,
    };
  }, [paymentData]);

  return (
    <TicketPaymentContainer className={'p016'}>
      {loading && <Spinner fullscreen />}
      <GoBack />
      <Heading title={lang.ticket_payment} />

      {!success ? (
        <Container>
          <img src={StripeLogo} alt={'stripe_logo'} className={'stripe-logo'} />

          <div className={'mt1'}>
            {paymentData.tickets && (
              <FangoutPaymentDetails
                className={`full-width ${
                  paymentData.tickets.length > 1 ? 'moreTickets' : ''
                }`}
              >
                <div className={'order'}>{lang.ticket_order}</div>
                {paymentData.tickets.map((t, i) => (
                  <TicketPaymentRow
                    key={i}
                    ticket={t}
                    className={'ticketRow'}
                    currency={paymentData.currency}
                  />
                ))}
                <Flex className={'justify-end align-end column mt1 fw500'}>
                  <div className={style.ticketsTotal}>
                    <span>{lang.ticket_payment_ticketsTotal}</span>
                    <span>
                      {totals.ticketsTotal}
                      {currencyMap[paymentData.currency]}
                    </span>
                  </div>
                  <div className={style.bankFee}>
                    <span>+ {lang.ticket_payment_bankFee}</span>{' '}
                    <span>
                      {totals.bankFee}
                      {currencyMap[paymentData.currency]}
                    </span>
                  </div>
                  <div className={style.total}>
                    {lang.total}: {totals.total}{' '}
                    {currencyMap[paymentData.currency]}
                  </div>
                </Flex>
              </FangoutPaymentDetails>
            )}
          </div>
          <div className={'FormGroup'}>
            <FormRow>
              <CardElement options={options} onChange={setCardElement} />
            </FormRow>
            {cElement?.error && (
              <div className={'text-danger fs12'}>
                {cElement.error?.message}
              </div>
            )}
          </div>
          <button
            disabled={
              !paymentData.tickets ||
              !stripe ||
              !cElement ||
              !cElement?.complete ||
              loading
            }
            onClick={handleSubmit}
            className={'mt2 mb2'}
          >
            {lang.pay}
          </button>
          {error && <div className='text-danger'>{error}</div>}
        </Container>
      ) : (
        <FlexCenter>
          <h4>Paid successfully</h4>
        </FlexCenter>
      )}
    </TicketPaymentContainer>
  );
};

const StripeTicketPayment = () => {
  const history = useHistory();
  const { data } = history.location?.state || {};
  if (!data) {
    history.push('/home');
    return null;
  }
  return (
    <WhitePaymentContainer>
      <Elements stripe={stripePromise}>
        <TicketPayment data={data} />
      </Elements>
    </WhitePaymentContainer>
  );
};

export default StripeTicketPayment;

const TicketPaymentContainer = styled(Container)`
  .moreTickets {
    .ticketRow {
      &:nth-child(2) {
        border-bottom: none;
      }
    }
  }

  .order {
    font-weight: 500;
    font-size: 18px;
  }

  .FormGroup {
    margin: 0;
  }

  img.stripe-logo {
    align-self: center;
    width: 100%;
    max-width: 300px;
  }
`;

const TicketPaymentRow = (props) => {
  const { currency } = props;
  const { title, description, numberOfTickets, ticketPrice } = props.ticket;

  return (
    <PaymentTicketRowComponent className={props.className}>
      <div className={'ticketDetails'}>
        <div className={'title'}>{title}</div>
        <div className={'priceNqty'}>
          <FlexCenter className={'price justify-end'}>
            {ticketPrice} {currencyMap[currency]} x {numberOfTickets}
          </FlexCenter>
          <FlexCenter className={'rowTotal justify-end'}>
            {numberOfTickets * ticketPrice} {currencyMap[currency]}
          </FlexCenter>
        </div>
      </div>
      <div>{description}</div>
    </PaymentTicketRowComponent>
  );
};

export const TicketRowComponent = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.secondBackground};
  padding: 5px 2px;
  gap: 10px;

  .ticketDetails {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    flex-grow: 1;

    .title {
      font-weight: 600;
    }
  }

  .priceNqty {
    display: flex;
    gap: 10px;
  }

  .ticketPrice {
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }

  .qty {
    min-width: 80px;

    .input {
      input {
        padding-right: 5px;
        padding-left: 10px;
        width: 80px;
      }
    }
  }
`;

const PaymentTicketRowComponent = styled(TicketRowComponent)`
  border-color: ${({ theme }) => theme.darkGreen};

  flex-direction: column;
  padding: 5px 6px;

  .ticketDetails {
    flex-direction: row;

    .title {
      flex-grow: 1;
    }

    .price {
      min-width: 100px;
    }

    .rowTotal {
      min-width: 60px;
    }
  }
`;
