import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { Container } from '../../styling/globalStyling';
import Spinner from '../../components/Spinner/Spinner';
import config from '../../config';
import axios from 'axios';
import Ticket from '../../components/ticket/Ticket';
import StreamingTicket from '../../components/ticket/StreamingTicket';

const TicketPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const getTicketData = (id: string) => {
    const url = config.baseApi + '/v1/public/tickets/findByInvalidationId';
    return axios.create().post(url, {
      ticketInvalidationId: id,
    });
  };

  const { data, isLoading, error } = useQuery(['ticket', id], ({ queryKey }) =>
    getTicketData(queryKey[1])
  );

  return (
    <TicketContainer>
      {(data as any) &&
        (data?.data?.ticketType.isStreaming ? (
          <StreamingTicket ticket={data?.data} />
        ) : (
          <Ticket
            expandable={false}
            defaultExpanded={true}
            ticket={data?.data}
          />
        ))}
      {isLoading && <Spinner fullscreen />}
      {error && (
        <>
          <div className={'text-center text-danger mt1'}>
            {(error as any)?.message}
          </div>
          <div
            className={'text-center mt1 fw500'}
            onClick={() => history.push('/')}
          >
            Home page
          </div>
        </>
      )}
    </TicketContainer>
  );
};

export default TicketPage;

const TicketContainer = styled(Container)`
  padding: 10px 6px;
  height: 100vh;
  background: ${({ theme }) => theme.secondBackground};

  .ticketComponent {
    position: unset;
  }

  .qrcode {
    margin-top: 20px;
  }
`;

const mockTicket = (id: string) => ({
  id: 4,
  ticketType: {
    id: 18,
    title: 'VIP',
    description: 'This is description of the VOP type of ticket',
    numberOfTickets: 100,
    ticketPrice: 50,
    currency: 'EUR',
    soldTickets: null,
  },
  invalidationId: '9ecbc81a-cfb2-4a4d-9b82-47af3c01f685',
  invalidationDate: null,
  isValid: true,
  purchaseDate: '2022-07-13T10:33:57.763118Z',
  show: {
    id: 5,
    showCode: null,
    celebrityDto: null,
    showTitle: 'Show T2',
    description: null,
    location: {
      title: 'Arena',
      lat: 44.81236,
      lon: 20.43059,
      countryCode: 'SRB',
      countryName: 'Serbia',
      city: 'Belgrade',
      state: 'Centralna Srbija',
      hereId: 'here:pds:place:688srywb-3c91559956ceb35573353140d713c96d',
    },
    showDate: '2022-07-31T18:00:00Z',
    durationHours: null,
    showType: 'SHOW',
    budget: null,
    currency: null,
    confirmationPoint: null,
    fundingUntilDate: null,
    streamingTicketPrice: null,
    ageRestriction: null,
    eventImage:
      'https://dev.fangout.app/photos/show_photo_95806743-8053-4043-b665-e008e8d4a2de.jpg',
    isDraft: null,
    ticketTypes: null,
  },
});
