import React, { useState } from 'react';
import styled from 'styled-components';
import Portal from '../portal/portal';
import Popup from '../popup/popup';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router-dom';

const HeadingComponent = styled.div`
  display: flex;
  padding: 15px 16px 14px 16px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  min-height: 56px;
`;

const Help = styled.div`
  font-size: 16px;
  position: absolute;
  right: 16px;
`;

const HeadingLogo = styled.i`
  font-size: 22px;
  position: absolute;
  left: 16px;
  top: 18px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 1;
  }
`;

const HedingTitle = styled.div`
  max-width: 230px;
  text-align: center;
`;

interface IHeading {
  title: string;
  help?: String[];
  trackingModalTitle?: string;
  showLogo?: boolean;
}

const Heading = (props: IHeading) => {
  const { title, help, trackingModalTitle, showLogo } = props;
  const history = useHistory();
  const lang = useSelector((state: any) => state.home.language);
  const [openHelpModal, setOpenHelpModal] = useState(false);

  const createHelpContent = () => {
    if (!help) return null;
    return (
      <div className={'mt1'}>
        {help.map((line, i) => (
          <div key={i} className={'mb1'}>
            {line}
          </div>
        ))}
      </div>
    );
  };

  const fireModalTrackingEvent = (title: string) => {
    ReactGA.event({
      action: 'Click',
      category: 'Modal',
      label: title,
    });
  };

  return (
    <HeadingComponent>
      {showLogo && (
        <HeadingLogo
          onClick={() => history.push('/')}
          className='icon-logo_glass'
        />
      )}
      <HedingTitle>{title}</HedingTitle>
      {help && (
        <Help>
          <i
            onClick={() => setOpenHelpModal(true)}
            className={'fas fa-question-circle clickable'}
          />{' '}
        </Help>
      )}
      {openHelpModal && (
        <Portal>
          <Popup
            position={'top'}
            content={createHelpContent()}
            cancelAction={() => {
              setOpenHelpModal(false);
              if (trackingModalTitle)
                fireModalTrackingEvent(trackingModalTitle);
            }}
            cancelText={lang.close}
          />
        </Portal>
      )}
    </HeadingComponent>
  );
};

export default Heading;
